import * as React from "react";
import { FunctionComponent, useState, useRef } from "react";
import { Button, Col, Row, Container } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";

import { isValidEmail } from "web-common/src/utils/isValidEmail";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import PrmService, { PrmUserData } from "../../services/PrmService";
import { isValidZipCode } from "../../utils/isValidZipCode";
import { isValidDob } from "../../utils/isValidDob";
import { event59 } from "../../analytics/event59";
import "./styles.scss";
import { IconError } from "../../images/icons/iconError";
import { FormCheckbox } from "../../templates/NewsletterSignUp";
import { RichTextSerializers } from "web-common/src/utils/richTextSerializers";
import Dropdown from "react-bootstrap/Dropdown";

export type Gender = { genderPlaceholder: string; genderFieldId: string };

export type NewsletterSignUpFormBlockInterface = {
  headline: {
    primaryText: string;
    secondaryText?: string;
    tertiaryText?: string;
  };
  showDobField: boolean;
  showZipCodeField: boolean;
  checkboxes: FormCheckbox[];
  genderSelect: Gender[];
  successfulSignUpMessage: string;
  name: string;
  language: string;
};

export const NewsletterSignUpFormBlock: FunctionComponent<NewsletterSignUpFormBlockInterface> = ({
  checkboxes,
  genderSelect,
  name,
  showDobField,
  showZipCodeField,
  successfulSignUpMessage,
  headline,
  language
}) => {
  const data = useStaticQuery(graphql`
    query NewsletterSignUpFormBlock($language: String, $market: String) {
      labels: sanityLabels {
        ...CtaLabelsFieldsFull
        ...FormsLabelsFieldsFull
        ...ErrorMessagesLabelsFieldsFull
      }
      ...LocalizedContext
    }
  `);

  const { prmApiKey, prmApiUrl, prmBrandCode, prmCampaignId, isoLanguage, isoCountry, extendedProfileApiUrl } =
    useSiteMetadata(language);

  const prmHeaders = {
    isoLanguage: isoLanguage,
    isoCountry: isoCountry,
    brandCode: prmBrandCode,
    campaignId: prmCampaignId
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [genderForm, setGenderForm] = useState("");
  const [genderPlaceholder, setGenderPlaceholder] = useState("");
  const [checkboxValues, setCheckboxValues] = useState<{ [index: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [checkboxErrorMessage, setCheckboxErrorMessage] = useState("");
  const [completionMessage, setCompletionMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const handleInputChange = (
    set: (value: string) => void,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    set(event.target.value);
  };

  let params = typeof window !== "undefined" ? new URLSearchParams(window.location.search) : null;
  const utm_source = params?.get("utm_source");
  const utm_campaign = params?.get("utm_campaign");

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setCheckboxValues(value => {
      value[name] = checked;

      return value;
    });
  };

  const handleLocalePlaceholder = (isoCountry: string) => {
    if (isoCountry === 'CA') {
      return "(yyyy-mm-dd)";
    } else if (isoCountry === 'BR') {
      return "(dd/mm/aaaa)";
    } else {
      return "(mm/dd/yyyy)";
    }
  }

  const handleGenderSelect=(e: any)=>{
    const genderForm = e.split("-")[0];
    const genderPlaceholder = e.split("-")[1];
    setGenderForm(genderForm);
    setGenderPlaceholder(genderPlaceholder);
  }

  return (
    <div className="page_newsletter-sign-up">
      <Container fluid>
        <Row>
          <Col>
            <h2>
              <span className="heading-line-1">{headline.primaryText}</span>
              {headline.secondaryText && (
                <span className="heading-line-2">{headline.secondaryText}</span>
              )}
              {headline.tertiaryText && (
                <span className="heading-line-1">{headline.tertiaryText}</span>
              )}
            </h2>

            {completionMessage ? (
              <Row>
                <Col lg={{ span: 6, offset: 3 }}>
                  <p aria-live="assertive" className="completion-message">
                    {completionMessage}
                  </p>
                </Col>
              </Row>
            ) : (
              <form className="newsletter-sign-up-form" onSubmit={event => handleSubmit(event)} ref={formRef} noValidate>
                <Row>
                  <Col lg={{ span: 6, offset: 3 }}>
                    <div className="questions-container">
                      <div>
                        <label htmlFor="first-name" className={`${submitted && (firstName ? 'valid' : 'invalid')}`}>
                          <input
                            id="first-name"
                            data-testid="first-name"
                            className="input-line"
                            type="text"
                            placeholder={data.labels.formsLabels?.firstName}
                            onChange={event => handleInputChange(setFirstName, event)}
                            required
                            aria-required="true"
                          />
                          *
                          {submitted && !firstName && <p className="error" aria-live="assertive"><IconError />{data.labels.errorMessages?.validFirstName}</p>}
                        </label>
                      </div>
                      <div>
                        <label htmlFor="last-name" className={`${submitted && (lastName ? 'valid' : 'invalid')}`}>
                          <input
                            id="last-name"
                            data-testid="last-name"
                            className="input-line"
                            type="text"
                            placeholder={data.labels.formsLabels?.lastName}
                            onChange={event => handleInputChange(setLastName, event)}
                            required
                            aria-required="true"
                          />
                          *
                          {submitted && !lastName && <p className="error" aria-live="assertive"><IconError />{data.labels.errorMessages?.validLastName}</p>}
                        </label>
                      </div>
                      {showZipCodeField && <div>
                        <label htmlFor="zip-code" className={` ${(zipCode || submitted) && (isValidZipCode(zipCode, isoCountry) ? 'valid' : 'invalid')}`}>
                          <input
                            id="zip-code"
                            data-testid="zip-code"
                            className="input-line"
                            type="text"
                            placeholder={data.labels.formsLabels?.zipCode}
                            onChange={event => handleInputChange(setZipCode, event)}
                            required
                            aria-required="true"
                          />
                          *
                          {((zipCode || submitted) && !isValidZipCode(zipCode, isoCountry)) && <p className="error" aria-live="assertive"><IconError />{data.labels.errorMessages?.validZipCode}</p>}
                        </label>
                      </div>}
                      <div>
                        <label htmlFor="email" className={` ${(email || submitted) && (isValidEmail(email) ? 'valid' : 'invalid')}`}>
                          <input
                            id="email"
                            data-testid="email"
                            className="input-line"
                            type="email"
                            placeholder={data.labels.formsLabels?.email}
                            onChange={event => handleInputChange(setEmail, event)}
                            required
                            aria-required="true"
                          />
                          *
                          {((email || submitted) && !isValidEmail(email)) && <p className="error" aria-live="assertive"><IconError />{data.labels.errorMessages?.validEmail}</p>}
                        </label>
                      </div>
                    </div>
                    {showDobField && <div>
                      <label htmlFor="date-of-birth" className={` ${(dob || submitted) && (isValidDob(dob, isoCountry) ? 'valid' : 'invalid')}`}>
                        <input
                          id="date-of-birth"
                          data-testid="dob"
                          className="input-line"
                          type="text"
                          placeholder={`${data.labels.formsLabels?.dob} ${handleLocalePlaceholder(isoCountry)}`}
                          onChange={event => handleInputChange(setDob, event)}
                          required
                          aria-required="true"
                        />
                        *
                        {((dob || submitted) && !isValidDob(dob, isoCountry)) && <p className="error" aria-live="assertive"><IconError />{data.labels.errorMessages?.validDateOfBirth}</p>}
                      </label>
                    </div>}
                    {genderSelect.length > 0 && <div className="gender-select">
                      <Dropdown onSelect={handleGenderSelect}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="signup-dropdown">
                        {genderPlaceholder || data.labels.formsLabels?.gender}
                        </Dropdown.Toggle>
                        <Dropdown.Menu  className="signup-dropdown-menu">
                          {genderSelect.map(({genderPlaceholder, genderFieldId})=> (
                            <Dropdown.Item eventKey={`${genderFieldId}-${genderPlaceholder}`}>{genderPlaceholder}</Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                        *
                        {submitted && !genderForm && <p className="error" aria-live="assertive"><IconError />{data.labels.errorMessages?.validGender}</p>}
                      </Dropdown>
                      </div>}
                    {checkboxes && (
                      <div className="t-and-c">
                        {checkboxes.map((checkbox, index) => (
                          <div key={`t-and-c-checkbox-${index}`} className="checkbox" data-testid={`t-and-c-checkbox-${index}`}>
                            {checkbox.hideCheckbox ? (
                              <BlockContent blocks={checkbox._rawLabel} serializers={RichTextSerializers()} />
                            ) : (
                              <>
                                <input
                                  type="checkbox"
                                  name={checkbox.type}
                                  onChange={event => handleCheckboxChange(checkbox.type, event.target.checked)}
                                  id={checkbox.type}
                                  required={checkbox.required}
                                />
                                <label htmlFor={checkbox.type} className='checkbox-label'>
                                  <BlockContent blocks={checkbox._rawLabel} serializers={RichTextSerializers()} />
                                </label>
                                {checkboxErrorMessage && !checkboxValues[checkbox.type] && <p className="checkbox-error" aria-live="assertive"><IconError />{checkboxErrorMessage}</p>}
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="submit-section">
                      <Button type="submit" variant="outline-light" className="submit-button">
                        {data.labels.ctaLabels?.signUpForNewsletter}
                      </Button>
                      {isLoading && <p>{data.labels.formsLabels?.loading}</p>}
                      {errorMessage && <p className="error" aria-live="assertive"><IconError />{errorMessage}</p>}
                    </div>
                  </Col>
                </Row>
              </form>
            )}

          </Col>
        </Row>
      </Container>
    </div>
  );

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const checkboxesLabel = window?.document.querySelectorAll('checkbox-label')

    for(const checkbox of checkboxesLabel) {
      checkbox.classList.remove("highlited-checkbox")
    }

    for(const checkbox of checkboxes) {
      if(checkbox.required && !checkboxValues[checkbox.type]) {
        const firstInvalidCheckbox = window?.document.querySelectorAll('.checkbox-label')[0];
        firstInvalidCheckbox?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        firstInvalidCheckbox.classList.add("highlited-checkbox")

        setCheckboxErrorMessage(data.labels.errorMessages?.requiredField || "");
        return;
      }
    }

    try {
      await setSubmitted(true);

      let isFormValid = formRef.current?.checkValidity() && isValidEmail(email);

      if (showZipCodeField && !isValidZipCode(zipCode, isoCountry)) isFormValid = false;
      if (showDobField && !isValidDob(dob, isoCountry)) isFormValid = false;

      if (!isFormValid) {
        const firstInvalidField = window?.document.querySelector('.newsletter-sign-up-form label.invalid');

        setErrorMessage(data.labels.errorMessages?.validForm as string);

        firstInvalidField?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        firstInvalidField?.querySelector('input')?.focus();
      } else {
        const prmPayload: PrmUserData = {
          firstName: firstName,
          lastName: lastName,
          email: email,
        };

        checkboxes.forEach(checkbox => {
          prmPayload[checkbox.type] = checkbox.hideCheckbox ? true : checkboxValues[checkbox.type];
        });

        if (showDobField) {
          if (isoCountry === 'CA') {
            prmPayload.dateOfBirth = dob;
          } else if (isoCountry === 'BR') {
              let d = dob.split("/")[0];
              let m = dob.split("/")[1];
              let y = dob.split("/")[2];
              let formatedDob = `${y}-${m}-${d}`
              prmPayload.dateOfBirth = formatedDob;
          } else {
            prmPayload.dateOfBirth = new Date(dob).toISOString();
          }
        }
        if (showZipCodeField) prmPayload.postCode = zipCode;
        if (genderSelect.length > 0) prmPayload.gender = genderForm;

        const response = await PrmService.createPrmProfile(prmApiKey, prmApiUrl, prmHeaders, prmPayload);

        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 30);
        const { UnileverId } = response;
        document.cookie = `UnileverId=${UnileverId}; expires=${expireDate.toUTCString()} `;
        if (typeof jest === 'undefined') event59();
        setCompletionMessage(successfulSignUpMessage);
      }
    } catch {
      setErrorMessage(data.labels.errorMessages?.signUpError as string);
      setIsLoading(false);
    }
  }
};

export default NewsletterSignUpFormBlock;
